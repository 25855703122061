@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "DuckInShipah-Kj2X";
	src: url("./fonts/DuckInShipah-Kj2X.woff2") format("woff2");
	font-display: swap;
}

body {
	transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
	@apply bg-white text-center text-black dark:bg-black dark:text-white;
}

.font-logo {
	font-family: "DuckInShipah-Kj2X";
	font-weight: normal;
	font-style: normal;
}